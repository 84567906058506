import { ITheme } from '.';
import colors from './colors';

const theme:ITheme = {
  title: 'dark',
  background: '#171717',
  onBackground: '#fff',
  surface: '#262626',
  onSurface: '#eee',
  placeholder: '#808080',
  ...colors,
};
export default theme;
