import React, {createContext, useState, useContext} from 'react';

interface Button{
  title: string;
  onClick (e:any):void;
}

interface HeaderContextData {
  setTitle(value: string): void;
  title: string;
  button: Button | null;
  setButton(value: Button | null):void;
}

const HeaderContext = createContext<HeaderContextData>({} as HeaderContextData);

export const HeaderProvider: React.FC = ({children}) => {
  const [title, setTitle] = useState<string>("Dashboard");
  const [button, setButton] = useState<Button | null>(null);
  return (
    <HeaderContext.Provider
      value={{title,setTitle,button,setButton}}>
      {children}
    </HeaderContext.Provider>
  );
};

export function useHeader() {
  const context = useContext(HeaderContext);
  return context;
}
