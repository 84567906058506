import { FormControl, InputLabel, MenuItem, Select as SelectMaterial } from '@material-ui/core';
import React from 'react';

interface IData{
  label: string;
  value: string;
}

interface InputProps {
  value: string;
  onSelect: (item: IData)=> void;
  label: string;
  data: IData[]
}

const Select = ({value,onSelect,label,data}:InputProps) => {
  return (
    <FormControl variant="outlined" style={{marginBottom:15}}>
        <InputLabel>{label}</InputLabel>
        <SelectMaterial
          value={value}
          label={label}
        >
          {data.map(item=> (
            <MenuItem key={item.value} onClick={()=> onSelect(item)} value={item.value}>{item.label}</MenuItem>
          ))}
        </SelectMaterial>
      </FormControl>
  )
}

export default Select;