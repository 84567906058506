import { TextField } from '@material-ui/core';
import React from 'react';

interface InputProps {
  value: string;
  setValue: (value:string)=> void;
  label: string;
  width?:string|number;
}
const Input = ({value,setValue,label,width}:InputProps) => {
  return (
    <TextField variant="outlined" value={value} label={label} onChange={(e)=> setValue(e.target.value)} style={{marginBottom:15,width}} />
  )
}

export default Input;