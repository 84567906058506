import React, {createContext, useState, useEffect, useContext} from 'react';
import * as auth from '../services/api/auth';
import Storage from '../services/Storage';
import AppError from '../services/AppError';
import api from '../services/api';
import Navigation from '../services/Navigation';
import { toast } from 'react-toastify';
import { STORAGE } from '../constants/storage';

export interface User {
  name: string;
  email: string;
  birthday: string;
  gender: number;
  cpf: string;
  cep?: number;
  biography?: string;
  job?: string;  
  password: string;
  rule: number;
}

interface AuthContextData {
  signed: boolean;
  user: User | null;
  token: string | null;
  loading: boolean;
  signIn(email: string, password: string): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storagedUser= Storage.getItem(STORAGE.USER)
    const storagedToken= Storage.getItem(STORAGE.TOKEN)
    if (storagedUser) {
      setUser(storagedUser);
      setToken(storagedToken);
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
    setLoading(false);
  }, []);

  async function signIn(email: string, password: string) {
    try {
      const response = await auth.signIn(email, password);
      setUser(response.user);
      Storage.setItem(STORAGE.USER,response.user)
      Storage.setItem(STORAGE.TOKEN,response.token)
      Navigation.push('/profissionais-pendentes');
      toast.success('Login feito com sucesso');
    } catch (error) {
      AppError({error});
    }
  }

  function signOut() {
    setUser(null);
    setToken(null)
    Storage.removeItem(STORAGE.USER);
    Storage.removeItem(STORAGE.TOKEN);
    Navigation.push('/');
  }

  return (
    <AuthContext.Provider
      value={{signed: !!user, user: user, token, loading, signIn, signOut}}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
