import lightTheme from './light';
import darkTheme from './dark';

export interface ITheme {
  title: string;
  background: string;
  onBackground: string;
  surface: string;
  onSurface: string;
  placeholder: string;
  regular: string;

  white: string;
  lighter: string;
  light: string;
  dark: string;
  darker: string;
  black: string;

  primary: string;

  transparent: string;
  success: string;
  failure: string;
}

interface IThemes {
  light: ITheme;
  dark: ITheme;
}

export default {
  light: lightTheme,
  dark: darkTheme,
} as IThemes;
