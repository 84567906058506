import { Checkbox, FormControl, InputLabel, ListSubheader, MenuItem, Select as SelectMaterial} from '@material-ui/core';
import React from 'react';

export interface IData{
  label: string;
  value: string;
}
export interface IDataMultiSelect{
  label: string;
  value?: string;
  group?: IData[];
}

interface InputProps {
  value: IData[];
  onSelect: (item: IData)=> void;
  label: string;
  data: IDataMultiSelect[]
}

const MultiSelect = ({value,onSelect,label,data}:InputProps) => {
  return (
    <FormControl variant="outlined" style={{marginBottom:15}}>
        <InputLabel>{label}</InputLabel>
        <SelectMaterial
          multiple
          value={value}
          label={label}
          renderValue={(selected) => (selected as IData[]).map(i=> i.label).join(', ')}
        >
          {data.map(item=> 
          item.group ? 
          (
            <>
            <ListSubheader>{item.label}</ListSubheader>
            {
              item.group.map(option=> (
            <MenuItem key={option.value} onClick={()=> onSelect(option as IData)} value={option.value}>
              <Checkbox checked={!!(value as IData[]).find(v=> v.value === option.value)} />
              {option.label}
            </MenuItem>
              ))
            }
            </>
          )
          :
          (
            <MenuItem key={item.value} onClick={()=> onSelect(item as IData)} value={item.value}>
              <Checkbox color="primary" checked={!!(value as IData[]).find(v=> v.value === item.value)} />
              {item.label}
            </MenuItem>
          ))}
        </SelectMaterial>
      </FormControl>
  )
}

export default MultiSelect;