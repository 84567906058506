import React from 'react';
import Routes from './routes';
import {ThemeProvider} from './contexts/theme';
import {AuthProvider} from './contexts/auth'
import { CategoriesProvider } from './contexts/categories';
import { HeaderProvider } from './contexts/header';

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <HeaderProvider>
        <AuthProvider>
          <CategoriesProvider>
            <Routes />
          </CategoriesProvider>
        </AuthProvider>
      </HeaderProvider>
    </ThemeProvider>
  );
};

export default App;
