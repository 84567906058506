import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Input from '../../../components/Input';
import { ContainerEdit } from '../styles';
import { toast } from 'react-toastify';
import { Delete, Add } from '@material-ui/icons';
import { ICategory, ISubCategory, updateCategory, updateSubCategory } from '../../../services/api/categories';
import { useHeader } from '../../../contexts/header';
import { createCategories, addSubCategory, deleteSubCategory } from '../../../services/api/categories';
import { useCategories } from '../../../contexts/categories';
import ImageUpload from '../../../components/ImageUpload';
import api from '../../../services/api';

interface IParams {
  category: ICategory;
}

interface File {
  file: Blob;
  lastModified:Date;
  lastModifiedDate:Date;
  name:string;
  preview:string;
  type:string;
  size:number;
}

const EditSubCategory: React.FC = () => {
  const {state} = useLocation<IParams | undefined>();
  const history = useHistory();
  const {categories, setCategories} = useCategories();
  const [category, setCategory] = useState<ICategory>(state?.category || {id: '', name: '', avatar: null, subCategories: []} as ICategory);
  const [name, setName] = useState<string>(category?.name);
  const [avatar] = useState<string| null>(category?.avatar);
  const [input, setInput] = useState<string>("");
  const {setTitle, setButton} = useHeader();
  const [imgCategory, setImgCategory] = useState<File>({preview:category?.avatar}as File);
  const [imgSubCategories, setImgSubCategories] = useState<File[] | []>([...category.subCategories.map(i=>({preview: i?.avatar} as File)),{preview: ""}] as File[]);

  const handleAddCategory = useCallback(async ()=> {
    const data = {
      name,
      category_id: category.id,
      avatar: category.avatar
    }
    const response = await createCategories(data);
    const stateCategory = [...categories];
    stateCategory.push({...response, subCategories: []});
    setCategories(stateCategory);
    history.goBack();
    toast.success("Categoria criada com sucesso!");
  }, [name, category, history,categories,setCategories]);

  const handleUpdate = useCallback(async()=>{
    const file = new FormData();
    let res = {data:{url:null}};
    if(category?.avatar !== imgCategory.preview){
      file.append("file",imgCategory.file);
      res = await api.post("file",file);
    }
    const data:ICategory = {
      id: category.id,
      name,
      avatar: category?.avatar !== imgCategory.preview ? res.data.url : avatar ,
      subCategories: category.subCategories
    }; 
    await updateCategory(data);
    const stateCategory = [...categories];
    const categoriesUpdated = stateCategory.map((item: ICategory) => item.id === category.id? data: item);
    setCategories(categoriesUpdated);
    toast.success("Categoria editada com sucesso!");
    history.goBack()
  },[name, history, category,imgCategory,setCategories,categories,avatar]);

  useEffect(()=>{
    setTitle("Editar Categoria")
    setButton({
      title: state? "Salvar" : "Criar",
      onClick: state? handleUpdate : handleAddCategory
    });
    return () => setButton(null)
  },[setTitle,handleUpdate,setButton,state,handleAddCategory]);

  const handleAddSubCategory = async (input: string, category_id: string)=> {
    let avatar = ""
    if(imgSubCategories[imgSubCategories.length-1].file){
      const img = new FormData();
      img.append("file",imgSubCategories[imgSubCategories.length-1].file);
      const {data} = await api.post("file",img);
      avatar = data.url;
    }
    const data = {
      name: input,
      category_id: category_id,
      avatar,
    }
    const response = await addSubCategory(data);
    const stateCategory = {...category};
    stateCategory.subCategories.push(response as ISubCategory);
    setCategory(stateCategory);
    setInput('');
    toast.success("Subcategoria adicionada com sucesso!");
  }

  const handleDeleteSubCategory = async (id: string)=> {
    await deleteSubCategory(id);
    const stateCategory = {...category};
    stateCategory.subCategories = stateCategory.subCategories.filter(item => item.id !== id);
    setCategory(stateCategory);
    toast.success("Subcategoria apagada com sucesso!");
  }

  const addImgCategory = (files:File[]) => {
    const file = files[0];
    setImgCategory(formatImage(file));
  };

  const formatImage = (file:File) => Object.assign(file, {
    file,
    preview: URL.createObjectURL(file)
  }) as File

  const addImgSubCategory = async (files:File[],index:number) => {
    const file = files[0] as File;
    const imgs = [...imgSubCategories];
    imgs[index] = formatImage(file)
    setImgSubCategories(imgs)
    if(index === category.subCategories.length) return;
    const img = new FormData();
    img.append("file",imgs[index].file);
    const {data} = await api.post("file",img);
    const {id,name} = category.subCategories[index];
    await updateSubCategory({id,name,avatar: data.url})
    const stateCategory = {...category};
    stateCategory.subCategories[index] = {id,name, avatar: data.url};
    setCategory(stateCategory);
  };

  return (
    <ContainerEdit>
      <ImageUpload addFile={addImgCategory} files={[imgCategory]} />
      <Input
        label="Nome" 
        value={name}
        setValue={setName}
      />
      {state&&(
        <>
          <h2>Sub-Categorias</h2>
          <List>
            {category.subCategories.map((i:ISubCategory,index:number)=> (
                  <ListItem key={i.id}>
                      <ImageUpload addFile={(files:File[])=>addImgSubCategory(files,index)} files={[imgSubCategories[index]]} size={50} />
                    <ListItemText
                      primary={i.name}
                    />
                    <ListItemSecondaryAction onClick={() => handleDeleteSubCategory(i.id)}>
                      <IconButton edge="end" aria-label="delete">
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
            ))}
            <ListItem key={"new"}>
                    <ImageUpload addFile={(files:File[])=>addImgSubCategory(files,imgSubCategories.length-1)} files={[imgSubCategories[imgSubCategories.length-1]]} size={50} />
                    <Input 
                      label="Nova Sub-categoria" 
                      value={input}
                      setValue={setInput}
                      />                  
                    <ListItemSecondaryAction onClick={() => handleAddSubCategory(input, category.id)}>
                      <IconButton edge="end" aria-label="delete">
                        <Add />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
              </List>
            </>
          )
        }
    </ContainerEdit>
  );
}

export default EditSubCategory;