import { Delete, Edit } from '@material-ui/icons';
import React, {  useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Table from '../../components/Table/index.js';
import { deleteProvider, IProvider, listProviders } from '../../services/api/providers';
import { useHistory } from 'react-router-dom';
import { useCategories } from '../../contexts/categories';
import { useHeader } from '../../contexts/header';
import { format ,parseISO} from 'date-fns';
import { STATUS_PROVIDER } from '../../constants/user';

function Providers() {
  const history = useHistory()
  const {subCategories} = useCategories();

  const [data,setData]= useState<IProvider[]| []>([]);
  
  const {setTitle} = useHeader()
  useEffect(()=>{
    setTitle("Profissionais")
  },[setTitle])

  const handleUpdateProvider = useCallback(async (provider:IProvider)=> {
    history.push("/editar-profissional",provider)
  },[history])

  const handleDeleteProvider = async (provider:IProvider)=> {
    await deleteProvider(provider.id);
    setData((value)=> value.filter(item=> item.id !== provider.id))
    toast.success("Profissional apagado com sucesso!")
  }

  const columns = useMemo(()=> [
    {
      id: "id",
      label: "",
      format: (value:number,row:IProvider)=> (
      <div style={{display:"flex",justifyContent:"space-around"}}>
        <Delete  onClick={()=> handleDeleteProvider(row)} style={{color: "#f55",cursor:"pointer"}} /> 
        <Edit onClick={()=> handleUpdateProvider(row)} style={{marginLeft:20, color: "#888",cursor:"pointer"}}/> 
      </div>
      )
    },
    {
      id: "name",
      label: "Nome",
    },
    {
      id: "cpf",
      label: "CPF",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "birthday",
      label: "Data Nasc.",
      format: (value:any)=> format(parseISO(value),'dd/MM/yyyy')
    },
    {
      id: "categories",
      label: "Categorias",
      format:(value:any)=> value.map((i:any)=> subCategories.find(sub=> sub.id === i?.subCategoryId)?.name).join(", ")
    },
    {
      id: "biography",
      label: "Biografia",
    },
    {
      id: "postCode",
      label: "CEP",
    }
  ],[handleUpdateProvider,subCategories]);

  useEffect(()=>{
    const getProviders = async()=>{
      const providers = await listProviders({status: STATUS_PROVIDER.CONFIRMED });
      setData(providers)
    }
    getProviders()
  },[]);

  return (
    <>
      <Table data={data} columns={columns} />
    </>
  );
}

export default Providers;