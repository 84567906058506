import { toast } from 'react-toastify';

interface IAppError {
  error?: any;
  message?: string;
}

export default function AppError({message, error}: IAppError) {
  const _message = message || error.response?.data?.message || error.message;
  return toast.error(_message);
}
