import { Button } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import PushNotification from '../../services/PushNotification';
import { toast } from 'react-toastify';

import Table from '../../components/Table/index.js';
import { useCategories } from '../../contexts/categories';
import { useHeader } from '../../contexts/header';
import { IProvider, listProvidersPendent, updateStatusProvider } from '../../services/api/providers';
import { format, parseISO } from 'date-fns';

function ProvidersPendent() {

  const {subCategories} = useCategories();
  const [data,setData]= useState<IProvider[]| []>([]);
  const {setTitle} = useHeader()
  useEffect(()=>{
    setTitle("Profissionais Pententes")
  },[setTitle])

  const columns = useMemo(()=> [
    {
      id: "status",
      label: "",
      align: "left",
      width: 210,
      format: (value:number,row:IProvider)=> (
      <div>
        <Button onClick={()=> handleAcceptProvider(row)} variant="contained" style={{height:25, width:80,marginRight:10, backgroundColor:"green",color:"white"}}>ACEITAR</Button> 
        <Button onClick={()=> handleRejectProvider(row)} variant="contained" style={{height:25, width:80,backgroundColor:"#f44",color:"white"}}>RECUSAR</Button> 
      </div>
      )
    },
    {
      id: "name",
      label: "Nome",
    },
    {
      id: "cpf",
      label: "CPF",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "birthday",
      label: "Data Nasc.",
      format: (value:any)=> format(parseISO(value),'dd/MM/yyyy')
    },
    {
      id: "categories",
      label: "Categorias",
      format:(value:any)=> value.map((i:any)=> subCategories.find(sub=> sub.id === i?.subCategoryId)?.name).join(", ")
    },
    {
      id: "biography",
      label: "Biografia",
    },
    {
      id: "postCode",
      label: "CEP",
    },
    
  ],[subCategories]);

  useEffect(()=>{
    const getProviders = async()=>{
      const providers = await listProvidersPendent();
      setData(providers)
    }
    getProviders()
  },[]);

  const handleAcceptProvider = async (provider:IProvider)=> {
    await updateStatusProvider(provider.id, 2);
    setData(value => value.filter(item=> item.id !== provider.id))
    toast.success("Profissional aceito com sucesso!")

    PushNotification.create({
      email:provider.email,
      title:`Cadastro plataforma Performance`,
      message:`Parabéns foi aprovado para ingressar em nossa plataforma como profissional!`
    })

  }
  const handleRejectProvider = async (provider:IProvider)=> {
    await updateStatusProvider(provider.id, 3);
    setData(value => value.filter(item=> item.id !== provider.id))

    PushNotification.create({
      email:provider.email,
      title:`Cadastro plataforma Performance`,
      message:`Sua solicitação de cadastro foi recusada, tente novamente nas próximas semanas.`
    })

  }

  return <Table data={data} columns={columns} />
}

export default ProvidersPendent;