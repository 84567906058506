import React, { useEffect, useState } from 'react';
import {parseISO, format} from 'date-fns';
import Table from '../../components/Table/index.js';
import { listAppointments, IResponseCreateAppointment } from '../../services/api/appointments';
import { useHeader } from '../../contexts/header';
import { getElementsByStatus } from '../../constants/appointment';
import { User } from '../../services/api/auth';

function Categories() {
  const {setTitle} = useHeader();
  const [data, setData] = useState<IResponseCreateAppointment[]>([]);
  
  useEffect(()=>{
    setTitle("Agendamentos")
  },[setTitle]);

  useEffect(() => {
    const get = async()=>{
      const appointments = await listAppointments();
      setData(appointments);
    }
    get();
  }, []);
  
  const columns = [
    {
      id: "user",
      format:(user:User) => user.name,
      label: "Cliente",
    },
    {
      id: "provider",
      format:(provider:User,row:any) => `${provider.name} (${row?.subCategory?.name})`,
      label: "Profissional",
    },
    {
      id: "date",
      format:(value:string) => format(parseISO(value), "dd/MM/yyyy 'às' HH:mm"),
      label: "Data e Hora",
    },
    {
      id: "status",
      format:(status:number) => getElementsByStatus(status).textLabel,
      label: "Status",
    },
    
  ];

  return (
      <Table data={data} columns={columns} />
  );
}

export default Categories;