import React, { useState } from 'react';
import { FormControl, Button } from '@material-ui/core';
import { Banner, Container, Input } from '../../components/global';
import { ContentLogin, Title } from './styles';
import {useAuth} from '../../contexts/auth';

function Login() {
  const { signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleLogin() {
    signIn(email, password);
  }
  return (
    <Container>
      <ContentLogin>
        <Banner />
        <Title>ÁREA ADMINISTRATIVA</Title>
        <FormControl style={{ width: 500 }}>
          <Input
            variant="outlined"
            label="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Input
            variant="outlined"
            label="Senha"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            Entrar
          </Button>
        </FormControl>
      </ContentLogin>

    </Container>
  );
}

export default Login;
