interface StatusAppoinment {
  PENDING: number;
  CONFIRMED: number;
  REFUSED: number;
  CANCELED: number;
  AWAIT_PAYMENT: number;
  DONE: number;
}

export const STATUS_APPOINMENT = {
  PENDING: 1,
  CONFIRMED: 2,
  REFUSED: 3,
  CANCELED: 4,
  AWAIT_PAYMENT: 6,
  DONE: 7,
} as StatusAppoinment;


export const getElementsByStatus = (action: number) => {
  return {
    [STATUS_APPOINMENT.PENDING]: {
      textLabel: 'Pendente',
      color: '#F2C94C',
    },
    [STATUS_APPOINMENT.AWAIT_PAYMENT]: {
      textLabel: 'Aguardando Pagamento',
      color: '#F2C94C',
    },
    [STATUS_APPOINMENT.DONE]: {
      textLabel: 'Concluído',
      color: '#6FCF97',
    },
    [STATUS_APPOINMENT.CONFIRMED]: {
      titleModal: 'AGENDAMENTO',
      labelNote: 'Observação',
      textButton: 'Aceitar',
      color: '#6FCF97',
      textLabel: 'Confirmado',
      fieldText: 'providerNote',
    },
    [STATUS_APPOINMENT.REFUSED]: {
      titleModal: 'AGENDAMENTO',
      labelNote: 'Observação',
      textButton: 'Recusar',
      color: '#EB5757',
      textLabel: 'Recusado',
      fieldText: 'providerNote',
    },
    [STATUS_APPOINMENT.CANCELED]: {
      titleModal: 'CANCELAMENTO',
      labelNote: 'Motivo',
      textButton: 'Cancelar',
      color: '#EB5757',
      textLabel: 'Cancelado',
      fieldText: 'cancelNote',
    },
  }[action];
};
