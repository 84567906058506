import React, { useState } from 'react';
import {
  Switch, Route, Router, Redirect, RouteProps} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Login from './pages/Login';
import AppHeader from './components/AppHeader';
import { ContentHeader } from './styles';
import {useAuth} from './contexts/auth';
import 'react-toastify/dist/ReactToastify.css';
import ProvidersPendent from './pages/ProvidersPendent';
import Categories from './pages/Categories';
import Providers from './pages/Providers';
import Navigation from './services/Navigation';
import EditProvider from './pages/Providers/Edit';
import EditCategory from './pages/Categories/Edit';
import Appointments from './pages/Appointments';
import Storage from './services/Storage';
import { STORAGE } from './constants/storage';

const CustomRoute = ({children,...rest }:RouteProps)=> {
  const {signed} = useAuth();
  let logged = signed || Storage.getItem(STORAGE.TOKEN);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (logged) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  )
}

export default function Routes() {
  const { signed } = useAuth();
  const [open, setOpen] = useState(false);
  return (
    <Router history={Navigation}>
      <ToastContainer
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      {signed && <AppHeader open={open} setOpen={setOpen} />}
      <Switch>
        {!signed && (
          <Route exact path="/">
            <Login />
          </Route>
        )}
        <ContentHeader showAppHeader open={open}>
          {/* <CustomRoute exact path="/dashboard" >
            <Dashboard/>
          </CustomRoute> */}
          <CustomRoute exact path="/profissionais-pendentes" >
            <ProvidersPendent />
          </CustomRoute>
          <CustomRoute exact path="/profissionais" >
            <Providers />
          </CustomRoute>
          <CustomRoute exact path="/editar-profissional" >
            <EditProvider />
          </CustomRoute>
          <CustomRoute exact path="/categorias" >
            <Categories />
          </CustomRoute>
          <CustomRoute exact path="/editar-categoria" >
            <EditCategory />
          </CustomRoute>
          <CustomRoute exact path="/appointments" >
            <Appointments />
          </CustomRoute>
        </ContentHeader>
      </Switch>
    </Router>
  );
}
