
interface IStorage {
  setItem: (key: string, value: any) => void;
  getItem: (key: string) => any | null;
  removeItem: (key: string) => any | null;
}

export default {
  setItem: (key: string, value: any) => {
    return localStorage.setItem(process.env.REACT_APP_APP_KEY+key, JSON.stringify(value));
  },
  getItem: (key: string) => {
    const response = localStorage.getItem(process.env.REACT_APP_APP_KEY+key);
    return response ? JSON.parse(response) : null;
  },
  removeItem: (key: string) => {
    return localStorage.removeItem(process.env.REACT_APP_APP_KEY+key);
  },
} as IStorage;
