import api from './index';

interface Response {
  token: string;
  user: User;
}

export interface User {
  name: string;
  email: string;
  birthday: string;
  gender: number;
  cpf: string;
  cep?: number;
  biography?: string;
  job?: string;  
  password: string;
  rule: number;
}

export async function signIn(
  email: string,
  password: string,
): Promise<Response> {
  const {data} = await api.post('session', {email, password,admin:true});
  api.defaults.headers.Authorization = `Bearer ${data.token}`;
  return data;
}
