import axios,{AxiosInstance} from 'axios';

interface CreateNotification {
  email: string;
  title: string;
  message: string;
  deliveryHour?:string;
}
interface Notification {
  app_id: string;
  filters: any;
  headings: any;
  contents: any;
  delivery_time_of_day?:string;
  delayed_option?:string;
}

class PushNotification {
  private notification: AxiosInstance
  constructor() {
    this.notification = axios.create({
      baseURL: 'https://onesignal.com/api/v1/notifications',
      headers: {
        Authorization: process.env.REACT_APP_ONESIGNAL_REST_API_KEY,
      },
    });
  }

  public create({email,title,message,deliveryHour}:CreateNotification) {
    const data = {
      app_id: process.env.REACT_APP_ONESIGNAL_APP_ID,
      filters: [{ field: 'email', value: email }],
      headings: { en: title },
      contents: { en: message },
    } as Notification;
    if(deliveryHour){
      data.delivery_time_of_day=deliveryHour;
      data.delayed_option='timezone';
    }
    return this.notification.post('', data);
  }

  public cancel(id:string) {
    return this.notification.delete(`${id}?app_id=${process.env.REACT_APP_ONESIGNAL_APP_ID}`);
  }
}

export default new PushNotification();