import React, {createContext, useState, useEffect, useContext, useMemo} from 'react';
import { ICategory, ISubCategory, listCategories } from '../services/api/categories';
import { useAuth } from './auth';

interface CategoriesContextData {
  setCategories(value: ICategory[] | ((prevState: ICategory[]) => ICategory[])): void;
  categories: ICategory[];
  subCategories: ISubCategory[];
}

const CategoriesContext = createContext<CategoriesContextData>({} as CategoriesContextData);

export const CategoriesProvider: React.FC = ({children}) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const {user} = useAuth();
  const subCategories = useMemo<ISubCategory[]>(()=>categories.map(i=> i.subCategories).flat(),[categories])

  useEffect(() => {
    if(!user) return;
    const get = async()=>{
      const data = await listCategories();
      setCategories(data);
    }
    get()
  }, [user]);

  return (
    <CategoriesContext.Provider
      value={{categories,setCategories, subCategories}}>
      {children}
    </CategoriesContext.Provider>
  );
};

export function useCategories() {
  const context = useContext(CategoriesContext);
  return context;
}
