import React, {createContext, useState, useContext} from 'react';
import themes, {ITheme} from '../styles/themes';
import {ThemeProvider as ThemeProviderStyledComponents} from 'styled-components';
import { createMuiTheme, ThemeProvider as ThemeProviderMaterial } from '@material-ui/core/styles';

interface ThemeContextData {
  theme: ITheme;
}

export const ThemeContext = createContext<ThemeContextData>(
  {} as ThemeContextData,
);

export const ThemeProvider: React.FC = ({children}) => {
  const [theme] = useState<ITheme>(themes.light as ITheme);
  
  const themeMaterial = createMuiTheme({
    palette: {
      primary: {
        main: '#771BA4',
      },
    },
  });
  return (
    <ThemeProviderStyledComponents theme={theme}>
      <ThemeProviderMaterial theme={themeMaterial}>
        <ThemeContext.Provider value={{theme}}>
          {children}
        </ThemeContext.Provider>
      </ThemeProviderMaterial>
    </ThemeProviderStyledComponents>
  );
};

export default function useTheme() {
  const context = useContext(ThemeContext);
  return context;
}
