import { ITheme } from '.';
import colors from './colors';

export default {
  title: 'light',
  background: '#fff',
  onBackground: '#333',
  surface: '#f7f7f7',
  onSurface: '#888',
  placeholder: '#808080',
  ...colors,
} as ITheme;
