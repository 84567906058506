import api from './index';

export interface IProvider {
  id:string;
  name: string;
  email: string;
  password: string;
  rule: number;
  gender: number;
  cpf: string;
  biography: string;
  job: string;
  birthday: string;
  cep: number;
  avatar?: string;
  phone?: number;
  address?: string;
  site?: string;
  latitude?: number;
  longitude?: number;
  status: number;
  categories?:any[];
}

export async function listProviders(
  params: object | null,
): Promise<IProvider[]> {
  const {data} = await api.get('providers', {params});
  return data;
}

export async function listProvidersPendent(): Promise<IProvider[]> {
  const {data} = await api.get('providers', {params:{status:1}});
  return data;
}

export async function updateProvider(provider:IProvider): Promise<IProvider> {
  const {data} = await api.put(`admin/providers/${provider.id}`,provider);
  return data;
}
export async function deleteProvider(id:string): Promise<IProvider> {
  const {data} = await api.delete(`admin/providers/${id}`);
  return data;
}
export async function updateStatusProvider(id:string,status:number): Promise<IProvider> {
  const {data} = await api.put(`admin/providers/${id}`,{id,status});
  return data;
}
