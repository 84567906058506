import React from "react";
import Dropzone from "react-dropzone";
import uploadHere from '../../assets/img/uploadhere.jpeg'
// for profile picture
class ImageUpload extends React.Component {
  state = { warningMsg: "" };

  onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      const message = "Please submit valid file type";
      this.setState({ warningMsg: message });
    } else {
      this.props.addFile(accepted);
      this.setState({ warningMsg: "" });

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then(value => {
      });
    }
  };

  render() {
    const { files,size } = this.props;
    const thumbsContainer = {
      width: size|| "100px",
      height: size ||"100px",
      borderRadius: "50%",
      objectFit: "cover",
      objectPosition: "center"
    };

    const thumbs = files.map(file => (
      <img style={thumbsContainer} src={file.preview || uploadHere } alt="upload" />
    ));

    const render =
      Object.keys(files).length !== 0 ? (
        files.map(file => <aside>{thumbs}</aside>)
      ) : (
        <p className="hello">+</p>
      );

    return (
      <div>
        <p>{this.state.warningMsg}</p>

        <Dropzone
          style={{
            width: size ||"100px",
            height: size ||"100px",
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "center",
            border: " 1px dashed",
            marginBottom:10,
            marginRight:20,
          }}
          multiple={false}
          accept="image/*"
          onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
        >
          {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
            // for drag and drop warning statement
            if (isDragReject) return "Please submit a valid file";
            return render;
          }}
        </Dropzone>
      </div>
    );
  }
}

export default ImageUpload;
