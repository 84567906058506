import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Input from '../../../components/Input';
import { IProvider, updateProvider } from '../../../services/api/providers';
import { ContainerEditProvider } from '../styles';
import Select from '../../../components/Select';
import { toast } from 'react-toastify';
import { useCategories } from '../../../contexts/categories';
import MultiSelect,{IData, IDataMultiSelect} from '../../../components/MultiSelect';
import { useHeader } from '../../../contexts/header';

const EditProvider: React.FC = () => {
  const {state} = useLocation<IProvider>();
  const {categories} = useCategories();
  const history = useHistory()
  const [name,setName] = useState<string>(state.name);
  const [email,setEmail] = useState<string>(state.email);
  const [avatar] = useState<string>(state?.avatar||"");
  const [biography,setBiography] = useState<string>(state?.biography);
  const [status,setStatus] = useState<string>(state.status.toString());
  const [gender,setGender] = useState<string>(state.gender.toString());
  const [userCategories,setUserCategories] = useState<IData[]>(categories.map(i=> i.subCategories).flat().filter(i=> !!state.categories?.find(c=> c.subCategoryId ===i.id)).map(i=> ({value: i.id, label: i.name})));
  
  const {setTitle,setButton} = useHeader()

  const dataCategories = useMemo<IDataMultiSelect[]>(()=>{
    return categories.map(i=> ({label: i.name, group: i.subCategories.map(i=> ({label: i.name, value: i.id}))}))
  },[categories]);

  const handleUpdateProvider = useCallback(async()=>{
    const data:IProvider = {
      ...state,
      name,
      email,
      avatar,
      biography,
      status: parseInt(status),
      gender: parseInt(gender),
      categories: userCategories.map(i=> ({subCategoryId: i.value}))
   };
   await updateProvider(data);
   toast.success("Profissional editado com sucesso!");
   history.goBack()
  },[name, email, avatar, biography, status, state,gender,userCategories,history]);

  useEffect(()=>{
    setTitle("Editar Profissional");
    setButton({
      title: "Salvar",
      onClick: handleUpdateProvider
    });
    return () => setButton(null)
  },[setTitle,handleUpdateProvider,setButton])

  return (
    <ContainerEditProvider >
      <Input 
        label="Nome" 
        value={name}
        setValue={setName}
        />
      <Input 
        label="Email" 
        value={email} 
        setValue={setEmail}
        />
      <Input 
        label="Biografia" 
        value={biography} 
        setValue={setBiography}
        />
      <MultiSelect 
        label="Categorias" 
        value={userCategories}
        data={dataCategories}
        onSelect={(item)=>{
          const isCheck = (userCategories as IData[]).find(i => i.value === item.value);
          setUserCategories(isCheck ? userCategories.filter(i=> i.value !== item.value) : [...userCategories, item] )
        }}
      />
      <Select 
        label="Status" 
        value={status}
        data={[
          {
            value: "1",
            label: "Pendente"
          },
          {
            value: "2",
            label: "Ativo"
          },
          {
            value: "3",
            label: "Inativo"
          },
        ]}
        onSelect={(item)=>setStatus(item.value)}
      />
      <Select 
        label="Sexo" 
        value={gender}
        data={[
          {
            value: "1",
            label: "Masculino"
          },
          {
            value: "2",
            label: "Feminino"
          },
          {
            value: "3",
            label: "Prefiro não Informar"
          },
        ]}
        onSelect={(item)=>setGender(item.value)}
      />

    </ContainerEditProvider>
  );
}

export default EditProvider;