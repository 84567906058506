import styled from "styled-components";

export const ContainerEdit = styled.div`
  display:flex;
  padding:20px;
  flex-direction: column;
`;
export const Row = styled.div`
  display:flex;
`;
export const ContentImage = styled.div`
  display:flex;
  flex-direction:column;
`;
