import { Delete, Edit } from '@material-ui/icons';
import React, {  useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import Table from '../../components/Table/index.js';
import { useHistory } from 'react-router-dom';
import { useCategories } from '../../contexts/categories';
import { deleteCategory, ICategory } from '../../services/api/categories';
import { useHeader } from '../../contexts/header';

function Categories() {
  const history = useHistory()
  const {categories, setCategories} = useCategories();
  const {setTitle,setButton} = useHeader();
  
  const handleCreate = useCallback(async () => {
    history.push("/editar-categoria")
  },[history]);

  useEffect(()=>{
    setTitle("Categorias")
    setButton({
      title: "Adicionar",
      onClick: handleCreate
    });
    return () => setButton(null)
  },[setTitle,setButton,handleCreate]);  

  const handleUpdate = useCallback(async (category:ICategory)=> {
    history.push("/editar-categoria",{category})
  },[history]);

  const handleDelete = useCallback(async (id:string)=> {
    await deleteCategory(id);
    setCategories((value)=> value.filter(item=> item.id !== id))
    toast.success("Categoria apagada com sucesso!")
  },[setCategories])

  const columns = useMemo(()=> [
    {
      id: "id",
      label: "",
      format: (value:number, row:ICategory)=> (
      <div style={{display:"flex",justifyContent:"space-around"}}>
        <Delete  onClick={()=> handleDelete(row.id)} style={{color: "#f55",cursor:"pointer"}} /> 
        <Edit onClick={()=> handleUpdate(row)} style={{marginLeft:20, color: "#888",cursor:"pointer"}}/> 
      </div>
      )
    },
    {
      id: "name",
      label: "Nome",
      minWidth:200,
    },
    {
      id: "subCategories",
      label: "Sub Categorias",
      format:(value:any)=> value.map((i:any)=> i.name).join(", ")
    },
  ],[handleUpdate,handleDelete]);

  return (
    <>
      <Table data={categories} columns={columns} />
    </>
  );
}

export default Categories;