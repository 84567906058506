import { User } from './auth';
import api from './index';

export interface IResponseCreateAppointment {
  id: string;
  provider_id: string;
  provider: User;
  user_id:string;
  user: User;
  date: Date;
  status: number;
  created_at: Date;
  updated_at: Date;
}

export async function listAppointments(): Promise<IResponseCreateAppointment[]> {
  const {data} = await api.get('appointments');
  return data;
}