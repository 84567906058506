import styled from 'styled-components';
import {
  TextField,
} from '@material-ui/core';

import banner from '../assets/img/banner.png';

export const Banner = styled.img.attrs({
  src: banner,
})`
  width: 390px;
  height: 100px;
  align-self: center;
  margin: 10px;
`;

export const Container = styled.div`
  display:flex;
  flex:1;
`;
export const Input = styled(TextField).attrs({
  style: { flex: 1, marginBottom: 10 },
})`
`;
