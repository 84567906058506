interface StatusProvider {
  PENDENT: number;
  CONFIRMED: number;
  REFUSED: number;
}
export const STATUS_PROVIDER = {
  PENDENT: 1,
  CONFIRMED: 2,
  REFUSED: 3,
} as StatusProvider;
