import api from './index';

export interface ISubCategory {
  id: string;
  name: string;
  avatar?: string;
}

export interface ICategory {
  id: string;
  name: string;
  avatar: string | null;
  subCategories: ISubCategory[];
}

export interface IRequestCreateSubCategory {
  name: string;
  category_id?: string;
  avatar?:string | null;
}

// CATEGORIES

export async function createCategories(category:IRequestCreateSubCategory): Promise<ICategory> {
  const {data} = await api.post('admin/categories', category);
  return data;
}
export async function listCategories(): Promise<ICategory[]> {
  const {data} = await api.get('categories');
  return data;
}
export async function updateCategory(category:ICategory): Promise<ICategory> {
  const {data} = await api.put(`admin/categories/${category.id}`,category);
  return data;
}
export async function deleteCategory(id:string): Promise<ICategory> {
  const {data} = await api.delete(`admin/categories/${id}`);
  return data;
}

// SUB CATEGORIES

export async function addSubCategory(subCategory:IRequestCreateSubCategory): Promise<IRequestCreateSubCategory> {
  const {data} = await api.post('admin/sub-categories',subCategory);
  return data;
}
export async function updateSubCategory(subCategory:ISubCategory): Promise<ISubCategory> {
  const {data} = await api.put(`admin/sub-categories/${subCategory.id}`,subCategory);
  return data;
}
export async function deleteSubCategory(id: string): Promise<ISubCategory> {
  const {data} = await api.delete(`admin/sub-categories/${id}`);
  return data;
}
